@import "../../utils/styles.scss";

.users-table {
  width: 100%;
  display: table;
  padding-bottom: 10px;
  border-collapse: separate;
  border-spacing: 0 5px;
  border: 2px solid $blue-color;
  border-radius: 10px;
  background-color: $blue-card-color;
}

.users-table_type_doc {
  max-width: 720px;
}

.users-table__head {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}

.users-table__tr {
}

.users-table__tr_type_head {
}

.users-table__tr_type_body {
  background-color: $white-color;
}

users-table__td-body_type_date .users-table__th-head {
  padding: 10px 0;
}

.users-table__body {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.users-table__td-body {
  //  padding: 5px 0;
  text-align: center;
}

.users-table__td-body_type_link {
  padding: 0;
}

.users-table__td-body_type_date {
  background-color: $blue-card-date-color;
  box-sizing: border-box;
}

.users-table__td-body_buttons {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.users-table__btn {
  display: block;
  margin-right: 5px;
  padding: 7px 10px;
  border: 5px solid;
  border-radius: 3px;
  color: $white-color;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  transition: $transition;
}

.users-table__btn:last-child {
  margin-right: 0;
}

.users-table__btn:hover {
  opacity: 0.75;
}

.users-table__btn_type_lock {
  border-color: orange;
  background-color: orange;
  background-image: url(../../images/icons/icons-white/lock.png);
}

.users-table__btn_type_create {
  border-color: $blue-color;
  background-color: $blue-color;
  background-image: url(../../images/icons/icons-white/add.png);
}

.users-table__btn_type_link {
  border-color: $blue-hover-color;
  background-color: $blue-hover-color;
  background-image: url(../../images/icons/icons-white/link.png);
}

.users-table__btn_type_edit {
  border-color: $green-color;
  background-color: $green-color;
  background-image: url(../../images/icons/icons-white/doc-edit.png);
}

.users-table__btn_type_delete {
  border-color: $err-color;
  background-color: $err-color;
  background-image: url(../../images/icons/icons-white/trash.png);
}

.hide-section {
  display: none;
}

.users-table__btn_type_success {
  border-color: $green-color;
  background-color: $green-color;
  background-image: url(../../images/icons/icons-white/check.png);
}

.users-table__btn_type_fail {
  border-color: $err-color;
  background-color: $err-color;
  background-image: url(../../images/icons/icons-white/cross.png);
}

.users-table__btn_type_qr {
  padding: 2px 5px;
  border-color: $blue-hover-color;
  background-color: $blue-hover-color;
}

.users-table__link {
  width: 100%;
  display: block;
  padding: 10px 0;
  color: $white-color;
  background-color: $blue-color;
  border: none;
  cursor: pointer;
  text-decoration: none;
  transition: $transition;
}

.users-table__link:hover {
  background-color: $blue-hover-color;
}
