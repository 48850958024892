@import "../../utils/styles.scss";

.users {
  display: flex;
  flex-direction: column;
}

.users_type_doc {
  align-items: center;
}

.users__not-found {
  margin: 0;
  color: $err-color;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
}

.users__btn {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 40px;
  right: 40px;
  display: block;
  border: none;
  border-radius: 50%;
  color: $white-color;
  background-color: $blue-hover-color;
  box-shadow: $shadow;
  text-decoration: none;
  cursor: pointer;
  transition: $transition;
}

.users__btn:hover {
  background-color: $blue-color;
}

.users__btn_logo {
  height: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.users__btn_logo_user-add {
  background-image: url(../../images/icons/icons-white/user-add.png);
}

.users__btn_logo_docs {
  background-image: url(../../images/icons/icons-white/doc-add.png);
}
