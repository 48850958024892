@import "../../utils/styles.scss";

.card {
  background-color: rgba(236, 236, 236, 1);
  min-height: 100vh;
}

.card__width {
  max-width: 720px;
  margin: 0 auto;
}

.card__head {
  color: $white-color;
  background-color: rgb(0, 140, 255);
}

.card__wrap-head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}

.card__avatar {
  max-width: 200px;
  width: 100%;
  height: 200px;
  display: block;
  background-size: cover;
}

.card__avatar_type_default {
}

.card__avatar_type_photo {
  border-radius: 50%;
}

.card__title {
  margin: 20px 0 0;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}

.card__links-head {
  display: flex;
  border: 2px solid $blue-color;
  border-bottom: none;
}

.card__link-head {
  width: 100%;
  padding: 15px 0;
  display: block;
  color: $white-color;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  text-decoration: none;
  border-right: 2px solid $blue-color;
  transition: $transition;
}

.card__link-head:last-child {
  border-right: none;
}

.card__link-head:hover {
  background-color: $blue-color;
}

.card__contacts {
  padding-bottom: 130px;
}

.card__link-contacts {
  display: block;
  padding: 15px;
  border-bottom: 1px solid $darkgreenish-color;
  text-decoration: none;
  transition: $transition;
}

.card__contact {
  display: block;
  margin: 0 0 5px;
  color: $main-color;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.card__link-span {
  margin: 0;
  display: block;
  color: $grey-color;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}
