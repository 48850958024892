$darkgreenish-color: rgba(53, 53, 52, 1);

.page {
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 40px;
}

.page__card {
  margin: 0 auto;
}

.title {
  margin: 0 auto 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}

.hide-el {
  width: 0.001px;
  height: 0.001px;
  position: absolute;
  z-index: -1;
  overflow: hidden;
  opacity: 0;
}

@media (max-width: 768px) {
  .page {
    padding: 0 20px;
  }
  .title {
    margin-bottom: 15px;
    font-size: 22px;
    line-height: 25px;
  }
}

@media (max-width: 480px) {
  .page {
    padding: 0 10px;
  }
  .title {
    font-size: 18px;
    line-height: 21px;
  }
}

/*
@media (max-width: 768px) {
}

@media (max-width: 540px) {
}

@media (max-width: 480px) {
}

*/
