@import "normalize";

.root {
  margin: 0;
  color: rgba(32, 32, 32, 1);
  background-color: rgba(255, 255, 255, 1);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}

/*
.available {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.available_disabled {
  display: none;
}

.loaded_hiding .available {
  transition: 0.3s opacity;
  opacity: 0;
}

.loaded .available {
  display: none;
}
*/