@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
/* IE */
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
/* Opera and prob css3 final iteration */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes asd {
  0% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.preloader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: white;
  z-index: 1001;
}

.preloader__logo {
  max-width: 100px;
  width: 100%;
  position: relative;
  top: 50%;
  left: 50%;
  margin-top: -57px;
  margin-left: -50px;
  text-align: center;

  -moz-animation: blink normal 1s infinite ease-in-out;
  -webkit-animation: blink normal 1s infinite ease-in-out;
  -ms-animation: blink normal 1s infinite ease-in-out;
  animation: blink normal 1s infinite ease-in-out;
}

.loaded_hiding .preloader {
  animation: asd normal 2s infinite ease-in-out;
  opacity: 1;
}

.loaded .preloader {
  display: none;
}
