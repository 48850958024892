@import "../../utils/styles.scss";

.popup {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: $darkgreenish-color;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
}

.popup.popup_opened {
  opacity: 1;
  pointer-events: all;
}

.popup__container {
  max-width: 420px;
  width: 100%;
  height: max-content;
  margin: 0 45px;
  padding: 34px 36px;
  border-radius: 10px;
  color: $main-color;
  background-color: $white-color;
  transform: scale(0);
  transition: 0.25s all;
}

.popup__container.popup__container_opened {
  transform: scale(1);
}

.popup__container_type_QR {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup__qr {
  max-width: 250px;
  width: 100%;
}

.popup__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.popup__link-back {
  color: $blue-color;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  transition: $transition;
}

.popup__link-back:hover {
  color: $blue-hover-color;
  font-weight: 500;
}

.popup__title {
  margin: 0 0 15px;
  color: $blue-hover-color;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
}

.popup__title_type_qr {
  margin-bottom: 5px;
}

.popup__title_type_qr-doc {
  margin-bottom: 15px;
}

.popup__subtitle {
  margin: 0;
  color: $main-color;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
}

.popup__subtitle_type_qr {
  margin-bottom: 15px;
  color: $blue-hover-color;
  font-size: 14px;
  line-height: 16px;
}

.popup__button-send {
  margin: 10px 0 0;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: $blue-hover-color;
  color: $white-color;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  transition: $transition;
}

.popup__button-send:hover {
  background-color: $blue-color;
}

.popup__button-close {
  position: absolute;
  top: -40px;
  right: -40px;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  color: rgba(255, 255, 255, 1);
  background: transparent;
  background-image: url("../../images/icons/button-close.png");
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.popup__button-close:hover {
  opacity: 0.6;
}

.popup__form {
  display: flex;
  flex-direction: column;
}

.popup__form-label {
  position: relative;
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.popup__form-input {
  margin: 10px 0 25px;
  padding: 5px;
  border: 2px solid;
  border-color: $blue-color;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.popup__form-doc-input {
  margin: 5px 0 20px;
  padding: 5px;
  border: 2px solid;
  border-color: $blue-color;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.popup__input-err {
  position: absolute;
  top: 42px;
  color: $err-color;
  font-size: 10px;
  line-height: 12px;
}

.popup__input-doc-err {
  position: absolute;
  top: 52px;
  color: $err-color;
  font-size: 10px;
  line-height: 12px;
}

.popup__btn-confirm {
  width: 100%;
  margin-top: 10px;
  padding: 10px 15px 12px;
  border: none;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  transition: all 0.3s ease-out;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
}

.popup__btn-confirm:hover {
  background-color: $blue-color;
}

.popup__btn-confirm_type_yes {
  background-color: $blue-hover-color;
}

.popup__btn-confirm_type_no {
  background-color: $blue-hover-color;
}
