@import "../../utils/styles.scss";

.auth {
  margin-bottom: 40px;
}

.auth__container {
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.auth__form {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.auth__label {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 14px;
}

.auth__text {
  font-style: normal;
  font-weight: 400;
}

.auth__input {
  margin: 8px 0 25px;
  padding: 15px;
  border: 2px solid;
  border-color: $blue-hover-color;
  border-radius: 5px;
  font-size: 14px;
  line-height: 16px;
}

.auth__input:focus {
  border-color: $green-color;
}

.auth__input-err {
  position: absolute;
  top: 75px;
  color: $err-color;
  font-size: 10px;
  line-height: 12px;
}

.auth__input_disabled {
  background-color: $grey-color-input;
  border-color: unset;
}

.auth__input_disabled:hover {
  cursor: no-drop;
}

.auth__label-checkbox {
  width: max-content;
  padding: 0 0 0 27px;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
}

.auth__input-checkbox {
  position: absolute;
  appearance: none;
  opacity: 0;
}

.auth__span-checkbox {
  position: absolute;
  width: 1em;
  height: 1em;
  margin: 0.2em 0 0 -24px;
  border-radius: 0.15em;
  background-color: $white-color;
  box-shadow: 0 0 0 0.2em $blue-hover-color;
  transition: $transition;
}

.auth__input-checkbox:checked + .auth__span-checkbox {
  background-color: $blue-hover-color;
  background-image: url(../../images/check.svg);
}

.auth__input-checkbox:focus + .auth__span-checkbox {
  box-shadow: 0 0 0 0.2em $blue-hover-color, 0 0 0 0.2em $green-color;
}

.auth__input-checkbox:disabled + .auth__span-checkbox {
  box-shadow: 0 0 0 0.2em $grey-color;
}

.auth__input-checkbox:checked:disabled + .auth__span-checkbox {
  background-color: $grey-color;
}

.auth__wrap {
  position: relative;
}

.auth__api-err {
  position: absolute;
  top: 100px;
  width: 100%;
  color: $err-color;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
}

.auth__btn-submit {
  width: 100%;
  margin-top: 50px;
  padding: 14px 0;
  border: none;
  border-radius: 5px;
  background-color: $blue-hover-color;
  color: $white-color;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
  transition: $transition;
}

.auth__btn-submit:hover {
  background-color: $blue-color;
}

.auth__btn-submit_disabled {
  background-color: $disabled-color;
  cursor: default;
}

.auth__btn-submit_disabled:hover {
  background-color: $disabled-color;
}

.auth__paragraph {
  margin: 40px 0 0;
  color: $darkgreenish-color;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.auth__link {
  color: $blue-color;
  text-decoration: none;
  transition: $transition;
}

.auth__link:hover {
  font-weight: 500;
  color: $blue-hover-color;
}

@media (max-width: 540px) {
  .auth__input {
    padding: 10px;
    font-size: 14px;
    line-height: 16px;
  }
  .auth__input-err {
    top: 65px;
  }
  .auth__btn-submit {
    margin-top: 40px;
    padding: 12px 0;
    font-size: 15px;
    line-height: 17px;
  }
  .auth__api-err {
    top: 85px;
  }
}

@media (max-width: 480px) {
  .auth__label {
    font-size: 11px;
    line-height: 13px;
  }
  .auth__input {
    margin-top: 5px;
    padding: 5px;
    font-size: 13px;
    line-height: 15px;
  }
  .auth__input-err {
    top: 50px;
    font-size: 9px;
    line-height: 11px;
  }
  .auth__label-checkbox {
    font-size: 11px;
    line-height: 13px;
  }
  .auth__btn-submit {
    margin-top: 35px;
    padding: 10px 0;
    font-size: 14px;
    line-height: 16px;
  }
  .auth__api-err {
    top: 75px;
    font-size: 10px;
    line-height: 12px;
  }
}

/*
.auth__btn-submit {
  margin-top: 50px;
  padding: 14px 0;
  font-size: 16px;
  line-height: 18px;
}
*/
