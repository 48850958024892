@import "../../utils/styles.scss";

.header {
  margin-bottom: 25px;
  padding: 25px 0;
  display: flex;
  border-bottom: 1px solid $darkgreenish-color;
}

.header__logo {
  max-width: 200px;
  width: 100%;
  height: 50px;
  margin-right: 50px;
  display: block;
  background-image: url("../../images/logo/logo.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.header__logo:last-child {
  margin-right: 0;
}

.header__nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.header__nav-wrapper {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  list-style-type: none;
}

.header__nav-item {
  margin-right: 10px;
}

.header__nav-item:last-child {
  margin-right: 0;
}

.header__nav-link {
  margin-right: 20px;
  color: $main-color;
  text-decoration: none;
  cursor: default;
}

.header__auth {
  display: flex;
  align-items: center;
}

.header__text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
}

.header__email {
  margin: 0 20px 0 0;
}

.header__link {
  margin-right: 20px;
  color: $blue-hover-color;
  text-decoration: none;
  transition: $transition;
}

.header__link:hover {
  color: $blue-color;
}

.header__sign {
  padding: 5px 15px 8px;
  border-radius: 5px;
  background-color: $blue-hover-color;
  color: $white-color;
  text-decoration: none;
  transition: $transition;
}

.header__sign:hover {
  background-color: $blue-color;
}

@media (max-width: 768px) {
  .header {
    margin-bottom: 15px;
    padding: 15px 0;
    justify-content: space-between;
  }
}

@media (max-width: 540px) {
  .header__logo {
    max-width: 50px;
    background-image: url("../../images/logo/logo-min.png");
  }
  .header__sign {
    padding: 5px 10px 6px;
  }
  .header__text {
    font-size: 14px;
    line-height: 16px;
  }
}

@media (max-width: 480px) {
  .header {
    padding: 10px 0;
  }
  .header__logo {
    height: 50px;
    background-image: url("../../images/logo/logo-min.png");
  }
}
