$main-color: rgba(32, 32, 32, 1);
$darkgreenish-color: rgba(53, 53, 52, 0.7);
$white-color: rgba(255, 255, 255, 1);
$blue-color: rgba(71, 189, 239, 1);
/* = подбор цвета = */
$blue-card-color: rgba(71, 189, 239, 0.25);
$blue-card-date-color: rgba(71, 189, 239, 0.15);
$disabled-color: rgba(139, 139, 139);

$blue-hover-color: rgba(0, 140, 255, 1);
$green-color: rgba(113, 193, 80, 1);
$err-color: rgba(238, 52, 101, 1);
$grey-color: rgba(155, 155, 155, 1);
$grey-color-avatar: rgba(155, 155, 155, 0.2);
$grey-color-input: rgba(155, 155, 155, 0.4);
$grey-color-bg: rgba(155, 155, 155, 0.15);
/* = подбор цвета = */
$grey-opacity-color: rgba(155, 155, 155, 0.2);

$shadow: 0px 5px 10px 1px $darkgreenish-color;

$transition: all 0.3s ease-in-out;
// $transition-popup: visibility 0s, opacity 0.5s step-start;
$transition-popup: all 0.3s step-start;