@import "../../utils/styles.scss";

.card-form__form {
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.card-form__wrap-inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.card-form__wrap-head {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.card-form__wrap-avatar {
  max-width: 180px;
  width: 100%;
  margin: 0 20px 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-form__wrap-fullname {
  width: 100%;
}

.card-form__wrap-items {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.card-form__avatar {
  max-width: 120px;
  width: 100%;
  max-height: 120px;
  height: 120px;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  background-size: cover;
}

.card-form__avatar_type_loaded {
  border-radius: 50%;
  background-color: $grey-color-avatar;
}

.card-form__image {
  max-width: 120px;
  width: 100%;
  height: 120px;
  background-size: cover;
}

.card-form__image_type_loaded {
  border-radius: 50%;
}

.card-form__avatar-preview {
  max-width: 120px;
  width: 100%;
}

.card-form__btn-del {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  background-image: url(../../images/icons/icons-dark/cross.svg);
  border: none;
  border-radius: 50%;
  box-sizing: border-box;
  overflow: hidden;
  background-color: $white-color;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: $transition;
}

.card-form__btn-del:hover {
  opacity: 0.8;
  box-shadow: $shadow;
}

.card-form__upload {
  position: relative;
}

.card-form__label-upload {
  display: inline-block;
  padding: 8px 15px 10px;
  border: none;
  border-radius: 5px;
  color: $white-color;
  background-color: $blue-hover-color;
  cursor: pointer;
  transition: $transition;
}

.card-form__label-upload:hover {
  background-color: $blue-color;
}

.card-form__input-upload-err {
  position: absolute;
  top: 40px;
  left: 0;
  color: $err-color;
  font-size: 10px;
  line-height: 12px;
}

.card-form__label {
  position: relative;
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.card-form__label_type_column-two {
  width: 48%;
}

.card-form__label_type_column-three {
  width: 32%;
}

.card-form__input {
  margin: 5px 0 15px;
  padding: 5px;
  border: 2px solid;
  border-color: $blue-hover-color;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 16px;
}

.card-form__input_disabled {
  background-color: $grey-color-input;
  border-color: unset;
}

.card-form__input_disabled:hover {
  cursor: no-drop;
}

.card-form__input-err {
  position: absolute;
  top: 50px;
  left: 0;
  color: $err-color;
  font-size: 10px;
  line-height: 12px;
}

.card-form__input_type_textarea {
  max-height: 100px;
  resize: none;
  font-family: inherit;
}

.card-form__info-err {
  position: absolute;
  top: 120px;
  left: 0;
  color: $err-color;
  font-size: 10px;
  line-height: 12px;
}

.card-form__wrap-btn {
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-form__btn-submit {
  max-width: 400px;
  width: 100%;
  margin-top: 50px;
  padding: 14px 0;
  border: none;
  border-radius: 5px;
  background-color: $blue-hover-color;
  color: $white-color;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
  transition: $transition;
}

.card-form__btn-submit:hover {
  background-color: $blue-color;
}

.card-form__btn-submit_disabled {
  background-color: $disabled-color;
  cursor: default;
}

.card-form__btn-submit_disabled:hover {
  background-color: $disabled-color;
}

.card-form__api-err {
  margin-top: 5px;
  color: $err-color;
  font-size: 11px;
  line-height: 13px;
}

.card-form__link {
  margin-top: 20px;
  color: $blue-hover-color;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-decoration: none;
  transition: $transition;
}

.card-form__link:hover {
  color: $blue-color;
  font-weight: 500;
}

@media (max-width: 768px) {
}

@media (max-width: 540px) {
  .card-form__wrap-inputs {
    align-items: center;
  }
  .card-form__wrap-items {
    flex-direction: column;
    align-items: center;
  }
  .card-form__label_type_column-two {
    max-width: 400px;
    width: 100%;
  }
  .card-form__label_type_column-three {
    max-width: 400px;
    width: 100%;
  }
  .card-form__label_type_info {
    max-width: 400px;
    width: 100%;
  }
  .card-form__btn-submit {
    margin-top: 40px;
    padding: 12px 0;
    font-size: 15px;
    line-height: 17px;
  }
}

@media (max-width: 480px) {
  .card-form__wrap-head {
    flex-direction: column;
    max-width: 400px;
    margin-bottom: 0;
  }
  .card-form__wrap-avatar {
    margin: 0 auto 20px;
  }
  .card-form__label {
    font-size: 11px;
    line-height: 13px;
  }
  .card-form__input-err {
    font-size: 9px;
    line-height: 11px;
  }
  .card-form__btn-submit {
    margin-top: 35px;
    padding: 10px 0;
    font-size: 14px;
    line-height: 16px;
  }
  .card-form__api-err {
    font-size: 10px;
    line-height: 12px;
  }
}
